<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            "
          >
            <h4 class="mb-sm-0">Detail Pembelian Saham</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Pembelian Saham</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!share_purchase">
        <detail-share-purchase-shimmer />
      </div>

      <div v-else>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title mb-3">Bukti Transfer</h5>
                <img
                  :src="
                    host +
                    '/storage/uploads/share_purchases/' +
                    share_purchase['evidance_of_transfer']
                  "
                  alt=""
                  class="rounded w-100"
                />
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Detail Transaksi</h5>

                <div class="row">
                  <div class="col-md-4">
                    <div class="d-flex mt-4">
                      <div
                        class="flex-shrink-0 avatar-xs align-self-center me-3"
                      >
                        <div
                          class="
                            avatar-title
                            bg-light
                            rounded-circle
                            fs-16
                            text-primary
                          "
                        >
                          <i class="ri-user-line"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1">Pembeli :</p>
                        <h6 class="text-truncate mb-0">
                          {{ share_purchase["buyer"]["name"] }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex mt-4">
                      <div
                        class="flex-shrink-0 avatar-xs align-self-center me-3"
                      >
                        <div
                          class="
                            avatar-title
                            bg-light
                            rounded-circle
                            fs-16
                            text-primary
                          "
                        >
                          <i class="ri-archive-drawer-line"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1">Saham :</p>
                        <h6 class="text-truncate mb-0">
                          {{ share_purchase["share_period"]["name"] }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-4">
                    <div class="d-flex mt-4">
                      <div
                        class="flex-shrink-0 avatar-xs align-self-center me-3"
                      >
                        <div
                          class="
                            avatar-title
                            bg-light
                            rounded-circle
                            fs-16
                            text-primary
                          "
                        >
                          <i class="ri-exchange-dollar-line"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1">Harga :</p>
                        <a href="#" class="fw-semibold">{{
                          currency.IDR(share_purchase["total_price"])
                        }}</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <div class="table-responsive">
                  <table class="table table-borderless mt-4">
                    <tbody>
                      <tr>
                        <th class="ps-0" scope="row">Status :</th>
                        <td class="text-muted">
                          <span
                            v-if="share_purchase['status'] == 'Y'"
                            class="badge rounded-pill bg-success"
                            >terverifikasi</span
                          >
                          <span
                            v-if="share_purchase['status'] == 'N'"
                            class="badge rounded-pill bg-danger"
                            >tidak terverifikasi</span
                          >
                          <span
                            v-if="share_purchase['status'] == 'P'"
                            class="badge rounded-pill bg-dark"
                            >menunggu</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Tanggal :</th>
                        <td class="text-muted">
                          {{
                            moment(share_purchase["created_at"]).format(
                              "DD MMMM YYYY"
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Nomor Transaksi :</th>
                        <td class="text-muted">
                          {{ share_purchase["transaction_number"] }}
                        </td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Jumlah Slot :</th>
                        <td class="text-muted">
                          {{ share_purchase["number_of_slot"] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="float-end">
                  <router-link
                    :to="{ name: 'share.purchase' }"
                    type="button"
                    class="btn btn-warning waves-effect waves-light me-2"
                    ><i
                      class="bx bx-chevrons-left font-size-16 align-middle me-2"
                    ></i>
                    Kembali
                  </router-link>
        
                  <div class="btn-group" role="group" v-if="share_purchase['status'] =='P'">
                    <button
                      :disabled="load"
                      id="btnGroupDrop1"
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        class="bx font-size-16 align-middle me-2"
                        :class="{ 'bx-loader bx-spin': load, 'bx-file': !load }"
                      ></i>
                      Validasi
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="btnGroupDrop1"
                      style=""
                    >
                      <li>
                        <button
                          class="dropdown-item"
                          @click="confirmValidation('valid')"
                        >
                          Valid
                        </button>
                      </li>
                      <li>
                        <button
                          class="dropdown-item"
                          @click="confirmValidation('unvalid')"
                        >
                          Unvalid
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <!--end row-->
              </div>
              <!--end card-body-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoint, host } from "../../host";
import moment from "moment";
import httpService from "../../services/http-service";
import errorService from "../../services/error-service";
import numberService from "../../services/number-service";
import Swal from "sweetalert2";
import DetailSharePurchaseShimmer from "./shimmer/DetailSharePurchaseShimmer.vue";
export default {
  components: {
    DetailSharePurchaseShimmer,
  },

  created() {
    this.id = this.$route.params.id;
    this.getTransaction();
  },

  data() {
    return {
      load: false,
      id: null,
      share_purchase: null,
      host: host,
      currency: numberService,
      moment: moment,
    };
  },
  methods: {
    async getTransaction() {
      try {
        let res = await httpService.get(
          endpoint["share.purchase"] + `/show/${this.id}`,
          {
            headers: httpService.authHeader(),
          }
        );
        this.share_purchase = res.data.data;
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    async confirmValidation(value) {
      Swal.fire({
        title: "Apakah anda yakin ingin mengubah status menjadi " + value + "?",
        text: "Setalah menggati status anda tidak dapat mengubanya lagi",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#405189",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, ganti",
        cancelButtonText: "Tidak, batalkan",
        buttonsStyling: true,
      }).then((isConfirm) => {
        if (isConfirm.value === true) {
          this.validateSharePurchase(value);
        }
      });
    },

    async validateSharePurchase(value) {
      try {
        this.load = true;
        let data = {
          status: value == "valid" ? "Y" : "N",
          _method: "PUT",
        };
        let res = await httpService.post(
          endpoint["share.purchase"] + `/status/${this.id}`,
          data,
          {
            headers: httpService.authHeader(),
          }
        );
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.getTransaction();
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },
  },
};
</script>

<style>
</style>