import axios from "axios";
import route from "../router";

export default new class NumberService {
    numberFormat(value) {

    }

    /**
     * 
     * @param {Number} value 
     * @returns 
     */
    IDR(value) {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
        }).format(value);
    }
}