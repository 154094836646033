<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">Bukti Transfer</h5>
          <free-style-shimmer
            :is-loading="true"
            height="200px"
            width="100%"
            border-radius="10px"
          />
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Detail Transaksi</h5>

          <div class="row">
            <div class="col-md-4">
              <div class="d-flex mt-4">
                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                  <div
                    class="
                      avatar-title
                      bg-light
                      rounded-circle
                      fs-16
                      text-primary
                    "
                  >
                    <i class="ri-user-line"></i>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <p class="mb-1">Pembeli :</p>
                  <free-style-shimmer
                    :is-loading="true"
                    height="11px"
                    width="300px"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="d-flex mt-4">
                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                  <div
                    class="
                      avatar-title
                      bg-light
                      rounded-circle
                      fs-16
                      text-primary
                    "
                  >
                    <i class="ri-archive-drawer-line"></i>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <p class="mb-1">Saham :</p>
                  <free-style-shimmer
                    :is-loading="true"
                    height="11px"
                    width="300px"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-4">
              <div class="d-flex mt-4">
                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                  <div
                    class="
                      avatar-title
                      bg-light
                      rounded-circle
                      fs-16
                      text-primary
                    "
                  >
                    <i class="ri-exchange-dollar-line"></i>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <p class="mb-1">Harga :</p>
                  <free-style-shimmer
                    :is-loading="true"
                    height="11px"
                    width="300px"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
          </div>

          <div class="mt-5">
            <paragraph-shimmer
              :is-loading="true"
              :lines="10"
              :random-size="true"
            />
          </div>
          <div class="float-end">
            <div>
              <free-style-shimmer
                :is-loading="true"
                height="40px"
                width="100px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FreeStyleShimmer, ParagraphShimmer } from "vue3-shimmer";
export default {
  components: {
    FreeStyleShimmer,
    ParagraphShimmer,
  },
};
</script>

<style>
</style>